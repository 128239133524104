import { Map } from "immutable";
import jsCookie from "js-cookie";
import Cookies from "universal-cookie";

import configGet from "../../config";

const cookies = new Cookies();

export const getCookie = (name, defaultValue) => {
  const value = cookies.get(name);
  if (!value) {
    return defaultValue;
  }
  return value;
};

export const setCookie = (name, value, options) => {
  cookies.set(name, value, {
    path: "/",
    domain: configGet("cookie_domain"),
    ...options,
  });
};

export const removeCookie = (name, options) => {
  cookies.remove(name, {
    path: "/",
    domain: configGet("cookie_domain"),
    ...options,
  });
};

export const persistPaths = {};

const setCookieJs = (name, value, expiry = 365, secure = false) => {
  jsCookie.set(name, value, { expires: expiry, path: "/", secure });
};

/**
 * Middleware to persist state in cookies.
 * @param {Object} paths
 * @param {Object} customOptions
 */
export const reduxCookiesMiddlewareImmutable = (
  paths = {},
  customOptions = {}
) => {
  const options = {
    logger: console.error,
    setCookie: setCookieJs,
    defaultEqualityCheck: (a, b) => a === b,
    defaultDeleteCheck: (value) => typeof value === "undefined",
    ...customOptions,
  };

  const _getVal = (state, path) => {
    const [section, ...pathPartsList] = path.split(".");
    if (!state[section]) {
      options.logger(`state not found at store.getState().${path}`);
      return null;
    }
    return state[section].getIn(pathPartsList, null);
  };

  return (store) => (next) => (action) => {
    const prevState = store.getState();
    const result = next(action);
    const nextState = store.getState();

    Object.keys(paths).forEach((pathToState) => {
      const prevVal = _getVal(prevState, pathToState);
      const nextVal = _getVal(nextState, pathToState);
      const state = paths[pathToState];
      const equalityCheck = state.equalityCheck || options.defaultEqualityCheck;
      const deleteCheck = state.deleteCheck || options.defaultDeleteCheck;

      if (!equalityCheck(prevVal, nextVal)) {
        if (deleteCheck(nextVal)) {
          options.setCookie(state.name, JSON.stringify(nextVal), 0);
        } else {
          options.setCookie(state.name, JSON.stringify(nextVal));
        }
      }
    });

    return result;
  };
};

export const getStateFromCookies = (preloadedState, paths, getCookie) => {
  Object.keys(paths).forEach((pathToState) => {
    const pathConf = paths[pathToState];
    const pathSplit = pathToState.split(".");

    // read cookies
    const storedState = getCookie(pathConf.name);

    if (storedState) {
      try {
        if (!preloadedState[pathSplit[0]]) {
          preloadedState[pathSplit[0]] = Map();
        }
        preloadedState[pathSplit[0]] = preloadedState[pathSplit[0]].setIn(
          pathSplit.slice(1),
          JSON.parse(storedState)
        );
      } catch (err) {
        console.error(
          `Unable to set state from cookie at ${pathConf.name}. Error: `,
          err
        );
      }
    }
  });

  return preloadedState;
};
