import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useCallback, useState } from "react";

import ProCircleIcon from "components/Pro/ProCircleIcon";
import ReachPopUp from "pages/PodcastView/Insights/Sections/insightReach/ReachPopUp";

import sendGAEvent from "utils/sendGAEvent";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  subTitle: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    margin: 0,
  },
  section: {
    padding: "0.5rem 0.5rem 0.875rem 0.5rem",
    borderBottom: `1px solid ${colours.borderGrey}`,

    ":last-child": {
      borderBottom: "none",
    },
  },
  sectionTop: {
    display: "grid",
    gridTemplateColumns: "1fr max-content max-content",
    marginBottom: "0.5rem",
    paddingRight: "0.25rem",
    alignItems: "center",
  },
  blue: {
    color: colours.darkishBlue,
  },

  infoIcon: {
    marginLeft: "0.5rem",
    fontSize: "1rem",
    color: "var(--color-neutral-d9)",
    paddingTop: 1,

    ":hover": {
      cursor: "pointer",
    },
    position: "relative",
  },
  infoIconActive: {
    color: "var(--color-neutral-d2)",
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
};

const ReachPopUpStyles = {
  container: {
    gridTemplateRows: "1fr",
  },
  outer: {
    marginLeft: 0,
    marginTop: 0,

    [ScreenSizes.mdAndAbove]: {
      marginTop: -255,
      marginLeft: -258,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const betaProCircleIconWithBetaStyles = {
  proCircleIcon: {
    position: "initial",
    marginLeft: "0.625rem",
    maxWidth: "90%",
  },
  proCircleIconAbberationPosition: {
    position: "relative",
  },
};

const FilterSection = (props) => {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    children,
    pro,
    title,
    learnMoreLink,
    renderPopupDetails,
    passedPopUpStyles,
    clickHandler,
    learnMoreTitle,
    type,
    bypassForAll,
  } = props;
  const [showInfo, setShowInfo] = useState(false);
  const hasPro = useUserHasPro();

  const { isWindowSizeOrLess } = useWindowSize();
  const onMobile = isWindowSizeOrLess("medium");

  const onInfoClick = useCallback(() => {
    if (!showInfo) {
      sendGAEvent({
        action: "infoIconClick",
        context: "Browse Charts Filters",
        page: "Browse Charts",
        type,
      });
    }
    return setShowInfo(!showInfo);
  }, [showInfo, type]);

  const handleClick = useCallback(
    (e) => {
      if (clickHandler) {
        e.preventDefault();
        clickHandler();
        sendGAEvent({
          action: "browseChartsFiltersCTALearnMoreClick",
          context: "Browse Charts Filters",
          page: "Browse Charts",
        });
      }
    },
    [clickHandler]
  );

  const wrapperRef = useOutsideClickHandler(() => setShowInfo(false));

  return (
    <div className={css(styles.section)}>
      <div className={css(styles.sectionTop)}>
        <h4 className={css(styles.subTitle, styles.blue)}>{title}</h4>
        <div
          className={css(styles.infoIcon, showInfo && styles.infoIconActive)}
        >
          {showInfo && (
            <ReachPopUp
              passedRef={wrapperRef}
              styles={passedPopUpStyles || ReachPopUpStyles}
            >
              <h2 className={css(styles.popUpTitle)}>{title}</h2>
              {renderPopupDetails(wrapperRef)}
              {learnMoreLink && (!hasPro || bypassForAll) && (
                <div>
                  {onMobile ? (
                    <a
                      className={css(styles.paraBold)}
                      href={learnMoreLink}
                      onClick={handleClick}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more &nbsp;&nbsp;
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        dataid="expand"
                      />
                    </a>
                  ) : (
                    <p className={css(styles.para)}>
                      Learn more about
                      <a
                        className={css(styles.link)}
                        href={learnMoreLink}
                        onClick={handleClick}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {learnMoreTitle || title}
                      </a>
                    </p>
                  )}
                </div>
              )}
            </ReachPopUp>
          )}
          <span onClick={onInfoClick}>
            <FontAwesomeIcon icon={faInfoCircle} dataid="infoCircle" />
          </span>
        </div>
        {pro && (
          <ProCircleIcon
            width=".5rem"
            height=".5rem"
            top="auto"
            right="auto"
            tooltipPlacement="bottom"
            tooltip="Pro Only"
            styles={betaProCircleIconWithBetaStyles}
            zIndex={9999}
            thin
            withTooltip
          />
        )}
      </div>
      {children}
    </div>
  );
};

FilterSection.propTypes = {};

export default memo(FilterSection);
