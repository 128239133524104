import axios from "axios";

import configGet, { configHas } from "../../../config";

import { getToken, clearToken } from "utils/auth";
import sendGAEvent from "utils/sendGAEvent";

try {
  axios.defaults.baseURL = configHas("api.serverBaseUrl")
    ? configGet("api.serverBaseUrl")
    : configGet("api.clientBaseUrl");
} catch (e) {
  // likely running via storybook
  console.warn(e);
}

let instance;

if (typeof window === "undefined") {
  // we're on the server, so pass a custom http and https agent with keepalive turned on
  const http = require("http");
  const https = require("https");

  const httpAgent = new http.Agent({ keepAlive: true });
  const httpsAgent = new https.Agent({ keepAlive: true });

  instance = axios.create({
    httpAgent,
    httpsAgent,
  });
} else {
  instance = axios.create();
}

instance.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization) {
      const token = await getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response, // Directly return successful responses.
  async (error) => {
    const originalRequest = error.config;

    if (
      !originalRequest._retry &&
      error.response.status === 401 &&
      error.response?.data?.message === "Token has expired"
    ) {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.

      try {
        const token = await getToken(true);

        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
        }

        return instance(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        console.error("Token refresh failed:", refreshError);

        clearToken();

        sendGAEvent({
          action: "authRefreshError",
          url: window.location.href,
          error: refreshError.message,
        });

        window.location.href = "/";

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error); // For all other errors, return the error as is.
  }
);

export default instance;
