import generateTransition from "utils/generateTransition";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

export const navItemStyles = {
  ...gStyles.fontBold,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "2px",
  paddingBottom: "2px",
  textDecoration: "none",
  color: colours.white,
  opacity: 0.75,
  fontSize: "0.875rem",
  textTransform: "uppercase",
  cursor: "pointer",
  outline: "none",

  transition: generateTransition({
    target: "visual",
    speed: "150ms",
  }),

  ":focus": {
    opacity: 1,
  },
  ":active": {
    opacity: 1,
  },
};

const navMenuStyles = {
  navItemOuter: {
    position: "relative",
    zIndex: 500,
    paddingLeft: 20,
    height: "100%",
    [ScreenSizes.smAndAbove]: {
      paddingLeft: 25,
    }, // no right menu, but wide enough for larger dividers
    [ScreenSizes.lgAndAbove]: {
      paddingLeft: 20,
    }, // right menu appears, need to squish them a bit

    [ScreenSizes.xlAndAbove]: {
      paddingLeft: 25,
    },

    ":first-child": {
      paddingLeft: 0,
      [ScreenSizes.lgAndAbove]: {
        borderRadius: "10rem",
        marginLeft: "-0.6rem",
        paddingLeft: "0.8rem",
        paddingRight: "0.8rem",
      },
      [createBreakpoint({ max: 1400 })]: {
        marginRight: "-0.3rem",
      },
    },

    [createBreakpoint({ max: 1400 })]: {
      maxHeight: "2.375rem",
      marginTop: ".4rem",
      marginBottom: ".4rem",
    },
  },
  hoveredItem: {
    [ScreenSizes.lgAndAbove]: {
      ":hover": {
        background: "var(--color-secondary-d4)",
      },
    },
  },
  navItemOuterLight: {
    ":first-child": {
      [ScreenSizes.lgAndAbove]: {
        ":hover": {
          background: colours.neutral,
        },
      },
    },
  },
  whiteNavBarLabel: {
    color: colours.bodyText,
  },

  navTitleBadge: {
    ...gStyles.fontSemiBold,
    position: "absolute",
    background: colours.primaryTextBackground,
    color: colours.white,
    fontSize: 9,
    margin: "0 auto",
    borderRadius: "2rem",
    textTransform: "uppercase",
    minWidth: 6,
    minHeight: 6,
    zIndex: 2,
    marginLeft: -6,
    left: "auto",
    padding: "0.3rem 0.4rem",
    bottom: "-9px",
  },
  navItem: {
    ...navItemStyles,

    ...getHoverQuery({
      opacity: 0.9,
      textShadow: "0 0.1em 0.2em rgba(0,0,0,0.5)",
    }),
  },
  navItemActive: {
    ...navItemStyles,
    opacity: 1,
  },
  navIcon: {
    alignSelf: "center",
    transition: generateTransition({
      targets: ["transform", "text-shadow"],
      speed: "150ms",
    }),
    transform: "scale(1.1,1.1)",
    marginRight: "0.6em",
    fontSize: "1rem",
    lineHeight: "1.5em",
    [ScreenSizes.smAndAbove]: {
      display: "block",
    },
  },
  navIconSvg: {
    width: "1rem",
    height: "1rem",
    marginTop: "-.1rem",
  },
  navIconWithContainer: {
    display: "none",
    [createBreakpoint({ min: 365 })]: {
      display: "flex",
    },
  },
  navTitleContainer: {
    ...gStyles.avalonBold,
    transition:
      "transform 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 100ms cubic-bezier(0.465, 0.183, 0.153, 0.946) 200ms",
    display: "block",
    textTransform: "none",
    letterSpacing: "0.01em",
    alignSelf: "center",
    opacity: 1,
    lineHeight: 1.5,
    verticalAlign: "middle",

    [ScreenSizes.smOnly]: {
      fontSize: "0.875rem",
    },
  },
  navTitle: {
    lineHeight: "2em",
  },
};

export default navMenuStyles;
